<template>
  <v-layout>
    <v-layout>
      <v-layout column>
        <validation-observer ref="observer" v-slot="{ invalid }">
          <v-layout class="border-primary rounded px-2 py-3">
            <v-layout column>
              <table class="table-price-list">
                <HeaderPriceList />
                <tbody>
                  <input-item-price
                    v-for="(item, index) in lstPrice"
                    :key="item.vId"
                    :item="item"
                    :idx="index"
                    :on-blur-weight="onBlurWeight"
                    :on-delete-item="onDeleteItem"
                  />
                </tbody>
                <tfoot>
                  <v-icon
                    class="ml-4"
                    color="primary"
                    size="48"
                    @click="addItem(false, false)"
                  >
                    mdi-plus-circle-outline
                  </v-icon>
                </tfoot>
              </table>
            </v-layout>
          </v-layout>
          <v-layout class="mt-3">
            <v-spacer />
            <v-btn
              color="primary"
              :disabled="invalid || invalidWeight"
              @click="onClickBtn"
            >
              {{ isCreated ? $t('save_changes') : $t('create_price_list') }}
            </v-btn>
          </v-layout>
        </validation-observer>
      </v-layout>
    </v-layout>
    <!-- Dialog loading -->
    <dialog-loading :is-show="showLoading" :message="$t('processing')" />

    <!-- notifications -->
    <dialog-notification
      v-model="showNoti"
      :icon-src="getIcon"
      :message="message"
    />

    <!-- confirm update -->
    <dialog-confirm
      v-model="showUpdate"
      :icon-src="getIcon"
      :message="message"
      :text-cancel="textCancel"
      :text-accept="textAccept"
      :on-cancel="onCancelUpdate"
      :on-accept="onAcceptUpdate"
    />

    <dialog-confirm-token-expried v-model="showConfirmTokenExpried" />
  </v-layout>
</template>

<script>
import DialogLoading from '@/components/dialog/DialogLoading.vue'
import DialogNotification from '@/components/dialog/DialogNotification.vue'
import DialogConfirm from '@/components/dialog/DialogConfirm.vue'
import DialogConfirmTokenExpried from '@/components/dialog/DialogConfirmTokenExpried.vue'
import constants from '@/constants'
import HeaderPriceList from './components/HeaderPriceList.vue'
import InputItemPrice from './components/InputItemPrice.vue'
import { PricesService } from '@/services/pricesService'
import { StorageService } from '@/services/storageService'
import mixinPriceList from './mixins/mixinPriceList.js'
import { StringUtils } from '@/helpers/stringUtils'

export default {
  components: {
    HeaderPriceList,
    InputItemPrice,
    DialogLoading,
    DialogNotification,
    DialogConfirm,
    DialogConfirmTokenExpried
  },
  mixins: [mixinPriceList],
  data: () => ({
    customerType: constants.customerType.personal,
    type: constants.pricesType.standard
  }),
  beforeMount() {
    this.initData()
  },
  methods: {
    async initData() {
      const results = await this.getPrices()
      if (results !== null && results.length > 0) {
        this.isCreated = true
        this.lstPriceOrigin = results
        await this.parseToLocalPrices(results)
        this.autoIncrease = this.getHighestPriceId() + 1
        this.checkInvalidWeight()
        this.bindMaxWeight()
      } else {
        this.isCreated = false
        this.initItem()
      }
    },
    async getPrices() {
      this.showLoading = true
      const pars = {
        type: constants.pricesType.standard,
        customerType: constants.customerType.personal,
        poId: StorageService.getPostOfficeId()
      }
      const { status, data } = await PricesService.getGuestPrices(pars)
      this.showLoading = false
      if (status === constants.statusCode.ok) {
        return data
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true
      }

      return null
    },
    async createPrices() {
      var valid = this.$refs.observer.validate()
      if (valid && !this.invalidWeight) {
        this.showLoading = true
        const pars = {
          customerPriceDtos: this.getRequestPars()
        }
        const { status, data } = await PricesService.customerCreate(pars)
        this.showLoading = false
        if (status === constants.statusCode.ok) {
          this.toggleDialogNoti({
            state: true,
            type: constants.typeAlert.success,
            msg: this.$t('create_success_prices')
          })
          this.initData()
          setTimeout(() => {
            this.toggleDialogNoti()
          }, constants.timeOut)
        } else if (StorageService.checkResponseExpried(status, data)) {
          this.showConfirmTokenExpried = true
        } else {
          this.toggleDialogNoti({ state: true, msg: data.message })
          setTimeout(() => {
            this.toggleDialogNoti()
          }, constants.timeOut)
        }
      }
    },
    getRequestPars() {
      const rqPars = []
      if (this.lenLstPrice > 0) {
        const arrPrice = this.sortPricesByWeight()
        arrPrice.forEach((i, index) => {
          let fromWeight = 0
          if (index > 1) {
            fromWeight = StringUtils.numStrToFloat(arrPrice[index - 2].weight)
          }

          const iOrigin = this.findItemOriginById(i.vId)
          const item = this.mappingRequestItem(i, fromWeight, iOrigin, i.isMax)
          rqPars.push(item)
        })

        if (this.lstDeleteOrigin.length > 0) {
          const arrDel = this.lstDeleteOrigin
          arrDel.forEach((item) => {
            item.status = constants.status.del
            item.hasUpdate = true
            rqPars.push(item)
          })
        }
      }

      return rqPars
    },
    mappingRequestItem(i, fromWeight, iOrigin) {
      const item = {}
      item['serviceId'] = constants.serviceType.normal
      item['poId'] = StorageService.getPostOfficeId()
      item['type'] = this.type
      item['customerType'] = this.customerType
      item['fromWeight'] = fromWeight
      item['toWeight'] = i.isMax ? null : StringUtils.numStrToFloat(i.weight)
      item['transportType'] = i.transportType
      item['intracityPrice'] = StringUtils.numStrToFloat(i.intracityPrice)
      item['intracityAccumulatePrice'] = StringUtils.numStrToFloat(
        i.intracityAccumulatePrice
      )
      item['intrazonePrice'] = StringUtils.numStrToFloat(i.intrazonePrice)
      item['intrazoneAccumulatePrice'] = StringUtils.numStrToFloat(
        i.intrazoneAccumulatePrice
      )
      item['interzonePrice'] = StringUtils.numStrToFloat(i.interzonePrice)
      item['interzoneAccumulatePrice'] = StringUtils.numStrToFloat(
        i.interzoneAccumulatePrice
      )
      item['status'] = i.status

      if (
        typeof iOrigin !== constants.undefined &&
        iOrigin !== null &&
        iOrigin.id > 0
      ) {
        item['id'] = iOrigin.id
        item['groupId'] = iOrigin.groupId
        item['hasUpdate'] = this.checkItemChange(iOrigin, item, i.isMax)
      } else {
        item['hasUpdate'] = false
      }

      return item
    },
    async updatePrices() {
      var valid = this.$refs.observer.validate()
      if (valid && !this.invalidWeight) {
        this.showLoading = true
        const pars = {
          customerPriceDtos: this.getRequestPars()
        }
        const { status, data } = await PricesService.customerUpdate(pars)
        this.showLoading = false
        if (status === constants.statusCode.ok) {
          this.toggleDialogNoti({
            state: true,
            type: constants.typeAlert.success,
            msg: this.$t('success_saved_info')
          })
          this.initData()
          setTimeout(() => {
            this.toggleDialogNoti()
          }, constants.timeOut)
        } else if (StorageService.checkResponseExpried(status, data)) {
          this.showConfirmTokenExpried = true
        } else {
          this.toggleDialogNoti({ state: true, msg: data.message })
          setTimeout(() => {
            this.toggleDialogNoti()
          }, constants.timeOut)
        }
      }
    }
  }
}
</script>

<style src="@/styles/form.scss" lang="scss" />
